<template>
  <div class="container">
    支付测试页面
  </div>  
</template>

<script>
import { Icon } from 'vant';
import rentModel from './rentModel.vue';
import { wxJsApi, wxJsPay } from '@/api/h5.js'
// import moment from 'moment';
// import { forEach } from 'lodash';

export default {
  components: {[Icon.name]: Icon, rentModel},
  
  created() {
    wxJsApi({ url: window.location.href }).then(msg1 => {
      console.log(msg1, '------> jssdk api')
      wxJsPay({ 
        id: this.$route.query.id || '1', 
        channelCode: 'wx_pub',
        channelExtras: {
          openid: sessionStorage.getItem('openid')
        },
      }).then(msg => {
        console.log(msg, '------> jssdk pay')

        this.$wxpay(msg1, msg, (res) => {
          console.log('pay res----->', res)
        })
      })
    })
  },
  
}
</script>


<style lang="less" scoped>

</style>  